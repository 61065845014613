@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@font-face {
  font-family: 'Librestile';
  src: url('./assets/fonts/LibrestileExtBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

.Montserrat{
  font-family: 'Montserrat', sans-serif;
}

.Librestile{
  font-family: 'Librestile', sans-serif;
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
  background-color: black;
}

.desktop-bg-roadmap-footer {
  background-image: url("assets/images/Background_Roadmap.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.fade-in-section-left {
  opacity: 0;
  transform: translateX(20vw); /* Move the element 20% of the viewport width to the right */
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section-left.is-visible {
  opacity: 1;
  transform: none; /* Reset the transformation */
  visibility: visible;
}

.fade-in-section-right {
  opacity: 0;
  transform: translateX(-20vw); /* Move the element 20% of the viewport width to the right */
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section-right.is-visible {
  opacity: 1;
  transform: none; /* Reset the transformation */
  visibility: visible;
}